.header {
    position: fixed;
    height: 90px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transition: .3s ease-in;
    overflow: hidden;
    /* background-color: #ebf5f3; */
}

.header-bg{
	background-color: #43cdbc;
}

.header .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1240px;
    margin: auto;
    height: 100%;
    padding: 0 1rem;
}


/* 
#root > div.header > nav > a > div{
	background-image: url("../assets/img/home/greenLogo.png");
    width: 125px;
    height: auto;
} */
/* .header .navbar a div {
	background-image: "../assets/img/home/greenLogo.png";
    width: 125px;
    height: auto;
} */

.header .nav-menu {
    display: flex;
}

.header .nav-item {
    padding: 1rem;
    font-weight: 500;
}

.header .nav-item a:hover {
    padding-bottom: 12px;
    border-bottom: 3px solid var(--secondary-color);
}

.prescrolled-link {
    color: black;
}

.scrolled-link {
    color: #ffffff;
}


.hamburger {
    display: none;
}

ul {
	list-style-type: none;
	text-decoration: none;
}

.nav-menu a {
	text-decoration: none;
	transition: color 0.2s ease-in;
}

@media screen and (max-width:940px) {
    .header {
        max-width: 100%;
    }
	
	.header-bg{
		background-color: #43cdbc;
	}

    .header .navbar {
        max-width: 100%;
        padding: 1.5rem;
    }

    .hamburger {
        display: block;
    }

    .nav-menu {
        position: fixed;
        left: -100%;
        top: 90px;
        flex-direction: column;
		background-color: #43cdbc;
        width: 100%;
        height: 90vh;
        z-index: 999;
        text-align: center;
        transition: .3s;
    }

    .nav-menu.active {
        left: 0;
		border-bottom: 2px white;
    }

    .nav-item {
        margin-top: 1.5rem;
        margin-right: 2rem;
    }

    .header .navbar img {
        width: 150px;
    }
}















