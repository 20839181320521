.blog-carousel {
    position: relative;
  }
  
  .blog-card {
    margin: 0 10px;
    background: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .blog-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  
  .blog-content {
    padding: 15px;
  }
  
  .blog-content h3 {
    margin-top: 0;
    font-size: 1.2rem;
  }
  
  .blog-content p {
    font-size: 0.9rem;
    color: #666;
  }
  
  .read-more-btn {
    display: inline-block;
    padding: 8px 15px;
    background-color: #2bc5b4;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    font-size: 0.9rem;
    transition: background-color 0.3s;
    margin: 5px 0px;
  }
  
  .read-more-btn:hover {
    background-color: #189b7f;
  }
  
  .custom-arrow-container {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
    pointer-events: none; /* Prevent interfering with slider navigation */
  }
  
  .custom-arrow {
    width: 40px;
    height: 40px;
    background-color: rgba(43, 197, 180, 0.7);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    pointer-events: all; /* Enable pointer events for the arrows */
    transition: background-color 0.3s;
  }
  
  .custom-arrow:hover {
    background-color: rgba(43, 197, 180, 1);
  }
  
  .custom-arrow svg {
    color: white;
    font-size: 20px;
  }
  
  .custom-prev-arrow {
    margin-left: -7px;
  }
  
  .custom-next-arrow {
    margin-right: 69px;
  }
  
  @media (max-width: 768px) {
    .custom-prev-arrow {
      margin-left: -13px;
    }
    .custom-next-arrow {
      margin-right: 60px;
    }
  }
  