.our-team {
  background-image: url("../assets/img/aboutus/our-team-bg.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

section.container.about-us .about-section p {
  font-size: 16px;
}
section.container.our-teams .container-body__card {
  flex-wrap: wrap;
  align-items: baseline;
}

section.container.our-teams
  .container-body__card
  .container-body__card-avatar
  p {
  font-size: 14px;
}
section.container.our-teams
  .container-body__card
  .container-body__card-avatar
  h3 {
  margin: 10px 0;
}
section.container.our-teams
  .container-body__card
  .container-body__card-avatar
  img.container-avatar__image {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  width: 150px;
  height: 150px;
  object-fit: cover;
}

img.container-body__icon {
  height: 140px;
}

@media screen and (max-width: 767px) {
  .our-team {
    background-image: none;
  }
  section.container .container-body.about-section .container-body__card > *,
  .container.our-teams .container-body__card > * {
    width: 100%;
    text-align: center;
  }
  section.container
    .container-body.about-section
    .container-body__card
    .container-body__icon {
    height: 100px;
  }
  section.container.our-teams .container-body__card {
    margin: 0 !important;
    padding: 50px !important;
    width: 100%;
  }
}
