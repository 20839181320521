.simple_container {
  width: 100%;
  position: relative;
  overflow: hidden;
  min-height: 95vh;
  background-color: #ebf5f4;
  animation: fadeLeft 1s ease-in-out;
}

.simple_sub_container {
  width: 95%;
  max-width: 1600px; 
  margin: 0 auto;
  display: flex;
  padding-top: 150px;
  /* padding-left: 40px; */
}

.simple_container-left {
  width: 45%;
}

.simple_container-right {
  width: 55%;
}

.simple-main__title {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.simple-paragraph {
  font-size: 1rem;
  padding-top: 1rem;
}

.hero-banner_container-left img.phone-img {
  width: 75%;
  display: block;
  margin: 0 auto;
}

/* Remove arrows from react-slick */
.slick-prev,
.slick-next {
  display: none !important;
}

/* Move dot indicators up */
.slick-dots {
  bottom: 20px !important;
}

/* Responsive styles */
@media (max-width: 1024px) {
  .simple_sub_container {
    flex-direction: column;
    padding-top: 5%;
  }

  .simple_container-left,
  .simple_container-right {
    width: 100%;
  }

  .simple-main__title {
    font-size: 1.8rem;
  }
}

@media (max-width: 768px) {
  .simple_container {
    padding: 2rem;
  }

  .simple_sub_container {
    flex-direction: column;
    padding-top: 10%;
  }

  .simple-main__title {
    font-size: 1.6rem;
  }

  .simple-paragraph {
    font-size: 0.9rem;
  }

  .hero-banner_container-left img.phone-img {
    width: 90%;
  }
}

@media (max-width: 480px) {
  .simple_container {
    padding: 1rem;
  }

  .simple_sub_container {
    flex-direction: column;
    padding-top: 15%;
  }

  .simple-main__title {
    font-size: 1.4rem;
  }

  .simple-paragraph {
    font-size: 1rem;
  }

  .home-carousel {
    padding-top: 2rem !important;
  }
}

@media (min-width: 1500px) {
  .simple_sub_container {
    padding-left: 100px;
  }
}

@media (min-width: 2200px) {
  .simple_sub_container {
    padding-left: 192px;
  }
}

@media (min-width: 2400px) {
  .simple_sub_container {
    padding-left: 192px;
  }
}

/* By default, show the desktop title and hide the mobile title */
.desktop-title {
  display: block;
}

.mobile-title {
  display: none;
}

/* For mobile screens (max-width: 768px), hide the desktop title and show the mobile title */
@media (max-width: 768px) {
  .desktop-title {
    display: none;
  }

  .mobile-title {
    display: block;
    font-size: 1.4rem; /* Adjust font size for mobile */
  }
}
