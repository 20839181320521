.insight-container {
  padding-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.insight-content {
  width: 100%;
  max-width: 700px;
  padding: 20px;
}

.big-img {
  object-fit: cover; /* No quotes */
  width: 100%;
  max-width: 700px;
  height: auto; /* No quotes */
  padding: 20px; /* Specify units */
}


h5 {
  text-align: left;
  margin-top: 20px;
}

.blog-body {
  text-align: left;
  margin-top: 20px;
}

.post-details {
  text-align: left;
  margin-top: 10px;
  font-size: 1rem;
  color: #6b7280; /* Light gray for text */
}

@media (max-width: 768px) {
  .insight-container {
    padding-top: 2rem;
  }
  .insight-content {
    padding: 10px;
  }
}

@media (min-width: 1500px) {
  .insight-content {
    width: 100%;
    max-width: 1200px;

  }

  .big-img {
    max-width: 900px;
  }
}