/* Carousel Wrapper */
.blog-carousel-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  position: relative;
  background-color: #f8f8f8 !important;
}

/* Carousel Styles */
.blog-carousel {
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
}

/* Card Container */
.blog-container {
  height: 100%;
  padding: 10px;
  
}

.blog-inner {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 400px;
  margin: 0 auto;
  cursor: pointer;
  pointer-events: auto; 
}

.blog-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  cursor: pointer;
  z-index: 1;
}

/* Card Content */
.blog-content {
  padding: 16px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.blog-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
}

.blog-excerpt {
  font-size: 14px;
  color: #555;
  margin-bottom: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  flex-grow: 1;
}

.blog-footer {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #eee;
}

.blog-reading-time {
  font-size: 12px;
  color: #999;
}

.read-more-btn {
  background-color: #2bc5b4;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
  z-index: 3;
}

.read-more-btn:hover {
  background-color: #0a897a;
}

/* Navigation Buttons */
.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
  transition: background-color 0.3s;
}

.nav-button:hover {
  background-color: rgba(255, 255, 255, 0.9);
}

.nav-button.prev {
  left: 0;
}

.nav-button.next {
  right: 0;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .blog-carousel-wrapper {
    padding: 20px 10px;
  }
}

@media (max-width: 768px) {
  .blog-carousel {
    padding: 0 30px;
  }
}

@media (max-width: 640px) {
  .blog-carousel {
    padding: 0 20px;
  }
}

/* Ensure Slick slider respects equal heights */
.slick-track {
  display: flex !important;
}

.slick-slide {
  height: auto;
  display: flex !important;
  justify-content: center;
}

.slick-track {
  display: flex !important;
  justify-content: center;
}

.slick-slide > div {
  height: 100%;
}

.slick-dots {
  z-index: -1;
}