.shopping-container {
    background-image: url("../assets/img/footer-images/footer-bg.png");
    background-repeat: no-repeat;
    background-size: cover; 
    /* background-size: 100%; */
    /* padding: 50px 0 0 0; */
    padding-bottom: 20px;
    padding-top: 50px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    margin: 0 auto;
    background-color: #f8f8f8 !important;
  }


  .shopping-container h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .shopping-container p {
    font-size: 1.1rem;
    color: #666;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 6px;
    padding-right: 6px;
  }

 
  
  .app-stores {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .store-button {
    display: flex;
    align-items: center;
    color: #000;
    padding: 10px 20px;
    border-radius: 8px;
    text-decoration: none;
  }
  
  .store-button img {
    width: 100px;
    height: 100px;
    margin-right: 10px;
  }
  
  .store-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .store-text span:first-child {
    font-size: 0.7rem;
    opacity: 0.8;
  }
  
  .store-text span:last-child {
    font-size: 1rem;
    font-weight: bold;
  }

  @media screen and (max-width: 570px) {
    .shopping-container {
      background-image: url("../assets/img/footer-images/footer-bg.jpeg");
      background-repeat: no-repeat;
      background-size: cover; 
      padding: 50px 0 0;
      text-align: center;
      margin: 0 auto;
    }
  }

  @media screen and (max-width: 420px) {
    .shopping-container {
      background-image: url("../assets/img/footer-images/footer-bg.jpeg");
      background-repeat: no-repeat;
      background-size: cover; 
      padding: 50px 0 0;
      text-align: center;
      margin: 0 auto;
    }
  }