.business-banner-container {
    width: 100%;
    background-color: #ebf5f4;
    padding: 10% 0;
  }
  
  .business-banner-content {
    max-width: 1600px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5%;
  }
  
  .business-banner-left {
    width: 55%;
  }
  
  .business-banner-right {
    width: 40%;
  }
  
  .business-title {
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .business-paragraph {
    font-size: 1rem;
    line-height: 1.6;
    color: #666;
  }
  
  .business-image {
    width: 100%;
    height: auto;
    max-width: 500px;
  }
  
  @media (max-width: 1200px) {
    .business-title {
      font-size: 2rem;
    }
  }
  
  @media (max-width: 992px) {
    .business-banner-content {
      flex-direction: column;
      text-align: left;
    }
  
    .business-banner-left,
    .business-banner-right {
      width: 100%;
    }
  
    .business-banner-left {
      margin-bottom: 2rem;
    }
  
    .business-image {
      max-width: 400px;
    }
  }
  
  @media (max-width: 768px) {
    .business-title {
      font-size: 1.8rem;
    }
  
    .business-paragraph {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .business-banner-container {
      padding: 30% 0;
    }
  
    .business-title {
      font-size: 1.5rem;
    }
  
    .business-paragraph {
      font-size: 1rem;
    }
  
    .business-image {
      max-width: 300px;
    }
  }

  @media (min-width: 1500px) {
    .business-banner-left {
      padding-left: 68px;
    }
  }
 
  @media (min-width: 2200px) {
    .business-banner-container {
        padding: 5% 0;
    }
  }
  
  @media (min-width: 2400px) {
    .business-banner-container  {
      padding: 5% 0;
    }
  } 