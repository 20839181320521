/* Footer.css */
.footer {
  background-color: #444f60;
  color: white;
  padding: 40px 20px 20px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-column {
  flex: 1;
  margin-right: 20px;
}

.footer-column:last-child {
  margin-right: 0;
}

.footer-logo {
  width: 115px;
  margin-bottom: 10px;
}

.footer-tagline {
  font-size: 14px;
  margin-bottom: 15px;
}

.social-icons {
  margin-bottom: 15px;
  color: #2cc5b4;
}

.social-icons a {
  color: #2cc5b4;
  font-size: 20px;
  margin-right: 15px;
  transition: all 0.2s ease-in;
}

.social-icons a:hover {
  transform: scale(1.2);
}

.footer-email {
  font-size: 14px;
}

.footer-column h3 {
  font-size: 18px;
  margin-bottom: 15px;
}

.footer-column ul {
  list-style-type: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 10px;
}

.footer-column a {
  color: white;
  text-decoration: none;
  transition: color 0.2s ease-in;
}

.footer-column a:hover {
  color: #2bc5b4;
}

.footer-bottom {
  text-align: center;
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid #98a9c3;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
  }

  .footer-column {
    margin-right: 0;
    margin-bottom: 30px;
    text-align: center;
  }

  .footer-logo {
    margin-left: auto;
    margin-right: auto;
  }

  .social-icons {
    justify-content: center;
    display: flex;
  }

  .footer-column ul {
    display: inline-block;
    /* text-align: left; */
  }
}
