/* @import url(https://fonts.googleapis.com/css?family=Open+Sans); */
.search {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 769px) {
  .search {
    padding: 0 350px;
  }
}

.searchTerm {
  width: 100%;
  border: 3px solid #2ac6b3;
  border-right: none;
  padding: 5px;
  height: 35px;
  border-radius: 5px 0 0 5px;
  outline: none;
}

.searchTerm:focus {
  color: #2ac6b3;
}

.searchButton {
  width: 40px;
  height: 35px;
  border: 1px solid #2ac6b3;
  background: #2ac6b3;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
}

.blogs-section {
  padding: 100px 43px;
}

.blogs-grid {
  display: grid;
  gap: 20px;
  margin-top: 20px;
}

.blog-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.blog-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.blog-content {
  padding: 16px;
}

.blog-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
}

.blog-excerpt {
  font-size: 14px;
  color: #555;
  margin-bottom: 16px;
}

.blog-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.read-more-btn {
  background-color: #2bc5b4;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.read-more-btn:hover {
  background-color: #0a897a;
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .blogs-grid {
    grid-template-columns: 1fr; /* Single column */
  }
  
  /* .blogs-section {
    padding: 50px 20px;
  } */
}

/* Desktop styles */
@media screen and (min-width: 769px) {
  .blogs-grid {
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
  }
}
